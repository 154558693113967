import { toast } from 'react-toastify';
import classNames from 'classnames';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import Button from '../../../widgets/Button';
import Card, { CardBody } from '../../../widgets/Card';
import WorkspaceRestService from '../services/WorkspaceRestService';
import { validateResponse } from '../../../utils/ResponseHelpers';

type CardProps = {
  idx: number;
  member: any;
  workspace: any;
};

const MemberCard = ({ idx, member, workspace }: CardProps) => {
  const dispatch = useAppDispatch();
  const handleDeleteMember = (userId: string) => {
    dispatch(
      WorkspaceRestService.deleteWorkspaceMember({
        workspaceId: workspace.id,
        userId,
      }),
    ).then((response) => {
      if (!validateResponse(response, 'Failed to delete workspace member')) return;

      toast.success('Workspace member deleted successfully');
    });
  };
  return (
    <Card stretch>
      <CardBody>
        <div className='row gx-0 justify-content-between'>
          <div className='col-3'>
            <div className='ratio ratio-1x1'>
              <div
                className={classNames(
                  'rounded-2',
                  'd-flex',
                  'align-items-center',
                  'justify-content-center',
                  'overflow-hidden',
                  'shadow',
                  'bg-primary',
                  'text-white',
                )}>
                <span className='fw-bold fs-1'>
                  {' '}
                  {member['user']['firstname'][0].toUpperCase()}{' '}
                </span>
              </div>
            </div>
          </div>
          <div className='col-8 d-flex flex-column justify-content-between'>
            <section className='row gx-0'>
              <section className='col p-0 d-flex flex-column'>
                <div className='h4 fw-bold'>
                  {member['user']['firstname'] + ' ' + member['user']['lastname']}
                </div>
                <div>
                  <span className='font-weight-bold'>{'Email: '}</span>
                  <span className='text-muted'>{member['user']['email']}</span>
                </div>
                <div>
                  <span className='font-weight-bold'>{'Role: '}</span>
                  <span className='text-muted'>{member['role']}</span>
                </div>
              </section>
              <section className='col-auto d-flex justify-content-center'>
                {member['role'] !== 'OWNER' && (
                  <Button
                    color='danger'
                    icon='Delete'
                    onClick={() => handleDeleteMember(member['user']['_id'])}></Button>
                )}
              </section>
            </section>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default MemberCard;
