import { createAsyncThunk } from '@reduxjs/toolkit';
import HttpClient from '../../../libs/HttpClient';

const fetchUserDetails = createAsyncThunk(
  'user/details',
  async () => {
    return (await HttpClient.get('/user/details')).data;
  }
);

const generateGuestToken = createAsyncThunk(
  'user/guest',
  async ({ client_id }: { client_id: string }) => {
    return (await HttpClient.get('/generate/guest-token', {
      params: {
        "guest_user_id": client_id
      }
    })).data;
  }
);

const logoutUser = createAsyncThunk(
  'user/logout',
  async () => {
    return (await HttpClient.get('/logout'));
  }
);

const checkAppInstalled = createAsyncThunk(
  'user/app/installed', 
  async () => {
    return (await HttpClient.get('/app/installed')).data;
  }
);

const getUserInvitations = createAsyncThunk(
  'user/invitations',
  async () => {
    return (await HttpClient.get('/user/invitations')).data;
  }
)

const UserRestService = {
  fetchUserDetails,
  generateGuestToken,
  logoutUser,
  checkAppInstalled,
  getUserInvitations
};

export default UserRestService;
