import { FC } from "react";

interface IYoutubeEmbedProps {
  embedId: string;
}


const YoutubeEmbed: FC<IYoutubeEmbedProps> = ({ embedId }) => (
  <div>
    <iframe
      width="100%"
      height="700"
      style={{ "borderRadius": "1rem" }}
      src={`https://www.youtube.com/embed/${embedId}`}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
);

export default YoutubeEmbed;
