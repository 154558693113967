import { useEffect } from 'react';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import Spinner from '../../../widgets/Spinner';
import WorkspaceRestService from '../services/WorkspaceRestService';
import AddMember from './AddMember';
import MemberCard from './MemberCard';

const WorkspaceMemberList = () => {
  const { data: workspace, members } = useAppSelector((store) => store.workspace.selected);
  const { data: user } = useAppSelector((store) => store.user.details);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (workspace.id === '') {
      return;
    }

    dispatch(WorkspaceRestService.fetchWorkspaceMembers(workspace.id));
  }, [dispatch, workspace.id]);

  return (
    <div className='row'>
      <div className='col-12'>
        <div className='h4 fw-bold py-3'>Members</div>
      </div>

      {members.isFetching ? (
        <div
          className='d-flex align-items-center justify-content-center'
          style={{ minHeight: '50vh' }}>
          <Spinner size={50} tag={'div'} />
        </div>
      ) : (
        <>
          {workspace.created_by === user.id && (
            <div className='col-md-3'>
              <AddMember />
            </div>
          )}
          {members.data.map((member: any, idx: number) => (
            <div className='col-md-3' key={idx}>
              <MemberCard idx={idx} member={member} workspace={workspace} />
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default WorkspaceMemberList;
