import { toast } from "react-toastify";

export const validateResponse = (
  response: any,
  defaultErrorMessage: string,
  silentNotification: boolean = false
) : boolean => {
    const payload = response.payload;

    if (payload === null || payload === undefined) {
      if (!silentNotification){
        toast.error(defaultErrorMessage);
      }
      return false;
    }

    if (payload.status === undefined || payload.status !== "SUCCESS") {
      if (!silentNotification){
        toast.error(payload.message || defaultErrorMessage);
      }
      return false;
    }

    return true;
}
