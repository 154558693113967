import { createSlice, current } from '@reduxjs/toolkit';
import RepositoryRestService from '../services/RepositoryRestService';

interface TRepositoryState {
  list: {
    data: any[],
    display_data: any[],
    isFetching: boolean,
  },
  owned_repository: {
    isFetching: boolean,
    data: any[],
  },
  selected: any[],
}

const initialState = {
  list: {
    data: [],
    display_data: [],
    isFetching: true,
  },
  owned_repository: {
    isFetching: true,
    data: [],
  },
  selected: [],
};

const updateRepositoryList = (state: any, action: any) => {
  const items = action.payload.items;
  state.list.data = items;

  state.list.display_data = [
    {
      _id: 'add-new-repository',
      name: 'add-new-repository',
      details: {
        owner: 'add-new-repository',
        fork: false,
      },
      issue_tracker_connected: true,
      audit: {
        updated_on: new Date().toISOString(),
        created_on: new Date().toISOString(),
      },
    },
  ].concat(items);

  if (items.length > 0) {

    if(state.selected.length === 0){
      state.selected = [items[0]._id];
    } else {
      const repoIds = new Set(items.map((repo: any) => repo["_id"]))
      state.selected = current(state.selected).filter((repoId: string) => repoIds.has(repoId))
    }
  }
  state.list.isFetching = false;
}

const updateOwnedRepositoryList = (state: any, action: any) => {
  state.owned_repository.data = action.payload.list;
  state.owned_repository.isFetching = false;
}

export const RepositorySlice = createSlice({
  name: 'repository',
  initialState,
  reducers: {
    resetState: () => initialState,
    updateSelectedRepositories: (state, action) => {
      state.selected = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(RepositoryRestService.fetchRepositories.fulfilled, updateRepositoryList);
    builder.addCase(RepositoryRestService.fetchRepositories.pending, (state, action) => {
      state.list.isFetching = true;
    });

    builder.addCase(RepositoryRestService.synchronizeRepositories.fulfilled, updateRepositoryList);
    builder.addCase(RepositoryRestService.synchronizeRepositories.pending, (state, action) => {
      state.list.isFetching = true;
    });

    builder.addCase(RepositoryRestService.fetchOwnedRepositories.fulfilled, updateOwnedRepositoryList);
    builder.addCase(RepositoryRestService.fetchOwnedRepositories.pending, (state, action) => {
      state.owned_repository.isFetching = true;
    });

  },
});

// Action creators are generated for each case reducer function
export const RepositoryActions = RepositorySlice.actions;

export default RepositorySlice;
