import { faker } from '@faker-js/faker';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { NotificationModel } from '../types/NotificationModel';

export const fetchNotifications = createAsyncThunk(
    'notification/list',
    async () => {
        return await new Promise<NotificationModel[]>(resolve => {
            setTimeout(() => {

                const types = ['danger', 'warning', 'info', 'success'];

                resolve(Array.from({ length: 15 }, () => {
                    const notification: NotificationModel = {
                        id: faker.datatype.uuid(),
                        title: faker.lorem.sentence(3),
                        message: faker.lorem.sentence(10),
                        type: types[Math.floor(Math.random() * types.length)],
                        date: faker.date.between('2022-01-01T00:00:00.000Z', '2022-05-01T00:00:00.000Z')
                    }
                    return notification
                }))

            }, 1000)
        });
    })

const NotificationRestService = {
    fetchNotifications
}

export default NotificationRestService
