import { createAsyncThunk } from '@reduxjs/toolkit';
import HttpClient from '../../../libs/HttpClient';
import Workspace from '../types/Workspace';

const createWorkspace = createAsyncThunk(
  'workspace/create',
  async (workspaceData: { name: string; integration: string }, { rejectWithValue }) => {
    try {
      return (await HttpClient.post('/workspace', workspaceData)).data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  });

const fetchWorkspaces = createAsyncThunk(
  'workspace/list',
  async (_, { rejectWithValue }) => {

    try {
      return (await HttpClient.get<Workspace[]>(
          '/workspace',
          {
            params: {
              "access_type": "ALL"
            }
          }
        )
      ).data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  });

const fetchWorkspace = createAsyncThunk(
  'workspace/id',
  async (workspaceId: string, { rejectWithValue }) => {

    try {
      let worskpaces = (await HttpClient.get<Workspace[]>(
          '/workspace',
          {
            params: {
              "access_type": "ALL"
            }
          }
        )
      ).data
      if (!worskpaces) {
        worskpaces = []
      }

      return worskpaces.filter(workspace => workspace?.id === workspaceId)[0];
    } catch (error: any) {
      return rejectWithValue(error);
    }
  });

const updateWorkspace = createAsyncThunk(
  'workspace/update',
  async ({ workspaceId, payload }: { workspaceId: string, payload: any }, { rejectWithValue }) => {

    try {
      return (
        await HttpClient.put('/workspace',
          payload,
          {
            params: {
              workspace_id: workspaceId
            }
          }
        )
      ).data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  });


const deleteWorkspace = createAsyncThunk(
  'workspace/delete',
  async (workspaceId: string, { rejectWithValue }) => {

    try {
      return (await HttpClient.delete('/workspace', {
        params: {
          workspace_id: workspaceId
        }
      })).data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  });

const fetchWorkspaceRepository = createAsyncThunk(
  'workspace/repository/list',
  async (workspaceId: string, { rejectWithValue }) => {

    try {
      return (await HttpClient.get('/workspace/repository', {
        params: {
          workspace_id: workspaceId
        }
      })).data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  });

const addWorkspaceMember = createAsyncThunk(
  'workspace/member/add',
  async (payload: { workspaceId: string, email: string }, { rejectWithValue }) => {

    try {
      return (await HttpClient.post(
        '/workspace/member',
        {
          "member_email": payload.email
        },
        {
          params: {
            "workspace_id": payload.workspaceId,
          }
        }
      )).data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  });

const deleteWorkspaceMember = createAsyncThunk(
  'workspace/member/detlete',
  async (payload: { workspaceId: string, userId: string }, { rejectWithValue }) => {

    try {
      return (await HttpClient.delete(
        '/workspace/member',
        {
          params: {
            "member_email": payload.userId,
            "workspace_id": payload.workspaceId
          }
        }
      )).data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  });

const addWorkspaceRepository = createAsyncThunk(
  'workspace/repository/add',
  async (
    payload: { workspaceId: string, repositoryId: string },
    { rejectWithValue }) => {

    try {
      return (await HttpClient.put(
        '/workspace/repository',
        {
          "repository_id": payload.repositoryId
        },
        {
          params: {
            "workspace_id": payload.workspaceId,
          }
        }
      )).data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  });

const deleteWorkspaceRepository = createAsyncThunk(
  'workspace/repository/delete',
  async (payload: { workspaceId: string, repositoryId: string }, { rejectWithValue }) => {

    try {
      return (await HttpClient.delete(
        '/workspace/repository',
        {
          params: {
            "workspace_id": payload.workspaceId,
            "repository_id": payload.repositoryId
          }
        }
      )).data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  });

const fetchWorkspaceAnalysisProgress = createAsyncThunk(
  'workspace/analysis/progress',
  async (workspaceId: string, { rejectWithValue }) => {

    try {
      return (await HttpClient.get(
        '/service/workflow/endpoint/workflow/status',
        {
          params: {
            "workflow_type": "vcs_data_collection",
            "workspace_id": workspaceId,
          }
        }
      )).data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

const fetchRepositoryAnalysisProgress = createAsyncThunk(
  'workspace/analysis/progress',
  async (workspaceId: string, { rejectWithValue }) => {

    try {
      return (await HttpClient.get(
        '/service/workflow/endpoint/workflow/status',
        {
          params: {
            "workflow_type": "vcs_data_collection",
            "repository_id": workspaceId,
          }
        }
      )).data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

const fetchWorkspaceMembers = createAsyncThunk(
  'workspace/members',
  async (workspaceId: string, { rejectWithValue }) => {

    try {
      return (await HttpClient.get(
        '/workspace/member',
        {
          params: {
            "workspace_id": workspaceId,
          }
        }
      )).data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

const fetchWorkspaceTimeline = createAsyncThunk(
  'workspace/timeline',
  async (workspaceId: string, { rejectWithValue }) => {

    try {
      return (await HttpClient.get(
        '/workspace/timeline',
        {
          params: {
            "workspace_id": workspaceId,
          }
        }
      )).data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

const WorkspaceRestService = {
  createWorkspace,
  fetchWorkspaces,
  fetchWorkspace,
  updateWorkspace,
  deleteWorkspace,
  deleteWorkspaceRepository,
  fetchWorkspaceRepository,
  addWorkspaceRepository,
  addWorkspaceMember,
  deleteWorkspaceMember,
  fetchWorkspaceAnalysisProgress,
  fetchRepositoryAnalysisProgress,
  fetchWorkspaceMembers,
  fetchWorkspaceTimeline
};

export default WorkspaceRestService;
