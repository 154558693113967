import * as Yup from 'yup';
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useEffectOnce } from 'react-use';
import Page from "../../../layouts/Page/Page";
import PageWrapper from "../../../layouts/PageWrapper/PageWrapper";
import Button from "../../../widgets/Button";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../../widgets/Card";
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from "../../../widgets/OffCanvas";
import Spinner from "../../../widgets/Spinner";
import FormGroupWrapper from "../../../widgets/forms/FormGroupWrapper";
import { Formik, useFormik } from "formik";
import FormGroup from '../../../widgets/forms/FormGroup';
import Input from '../../../widgets/forms/Input';
import Select from '../../../widgets/forms/Select';
import Icon from "../../../wrappers/Icon";
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import IntegrationRestService from '../services/IntegrationRestService';
import RepositoryRestService from './../../repository/services/RepositoryRestService';
import { validateResponse } from '../../../utils/ResponseHelpers';


const JiraConfigurationSchema = Yup.object().shape({
  configurationName: Yup.string().required('Configuration name is required'),
  serverUrl: Yup.string().required('Jira server url is required'),
  restApiVersion: Yup.string(),
  accessToken: Yup.string().required('Integration access token (IAT) is required'),
});


const JiraConfiguration = () => {

  const { isFetching, data: configurations } = useSelector(
    (store: any) => store.integration.jira.configurations
  );
  const { data: repositories } = useSelector(
    (store: any) => store.repository.owned_repository
  );

  const [jiraConfigCanvasStatus, setJiraConfigCanvasStatus] = useState(false);
  const [allConfigurationState, setAllConfigurationState] = useState([
    {
      "text": "Select Configuration",
      "value": ""
    }
  ]);

  const dispatch = useAppDispatch();

  useEffectOnce(() => {
    dispatch(IntegrationRestService.fetchJiraConfigurations());
    dispatch(RepositoryRestService.fetchOwnedRepositories("ALL"))
  })

  const jiraConfigurationFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      configurationName: "",
      serverUrl: "",
      restApiVersion: "latest",
      accessToken: ""
    },
    validationSchema: JiraConfigurationSchema,
    validateOnChange: false,
    onSubmit: (values: {
      configurationName: string;
      serverUrl: string;
      restApiVersion: string;
      accessToken: string
    }) => {
      dispatch(IntegrationRestService.addJiraConfiguration(values))
        .then((response) => {
          if (!validateResponse(response, "Failed to add jira configuration")) return;

          toast.success("Jira configuration added successfully");
          setJiraConfigCanvasStatus(false);
          dispatch(IntegrationRestService.fetchJiraConfigurations());
        })
    },
  });

  useEffect(() => {

    if (configurations === undefined || configurations.length === 0) {
      return;
    }

    setAllConfigurationState(() => [
      {
        "text": "Select Configuration",
        "value": ""
      },
      ...configurations.map((configuration: any) => ({
        "text": configuration["configuration_name"],
        "value": configuration["id"]
      }))
    ]);

  }, [configurations])


  const handleDeleteJiraConfiguration = (configuration_id: string) => {
    dispatch(IntegrationRestService.deleteJiraConfiguration(configuration_id)).then((response) => {
      if (!validateResponse(response, "Failed to delete jira configuration")) return;

      toast.success("Jira configuration deleted successfully");
      dispatch(IntegrationRestService.fetchJiraConfigurations());
    });
  }

  const handleAddJiraConfiguration = () => {
    setJiraConfigCanvasStatus(true);
  }

  const handleMappingUpdate = (values: any) => {
    dispatch(RepositoryRestService.updateRepositoryIssueTrackerMapping(values)).then((response) => {
      if (!validateResponse(response, "Failed to update repository mapping")) return;

      toast.success("Repository mapping updated successfully");
      dispatch(RepositoryRestService.fetchOwnedRepositories("ALL"))
    });

  }

  return (
    <PageWrapper
      isProtected={false}
      title={'Home'}
      className='p-0'>
      <Page container="fluid" className='p-0'>
        <section className="container-fluid p-5">
          <div className='row'>
            <div className='col-12'>
              <div className='h4 fw-bold py-3'>Configurations</div>
            </div>
          </div>
          <div className="row">
            <div className='col-md-3 integration'>
              <Card stretch>
                <CardHeader className='p-2 card-header integration-header'>
                  <CardLabel className="w-100 d-flex justify-content-center">
                    <CardTitle tag='h4' className='h5' >
                      {"Added Jira Configuration"}
                    </CardTitle>
                  </CardLabel>
                </CardHeader>
                <CardBody className="row justify-content-center">
                  <div className="col-9 d-flex justify-content-center align-items-center">
                    <Button
                      color='primary'
                      icon='Add'
                      onClick={(event: any) => {
                        event.stopPropagation();
                        handleAddJiraConfiguration()
                      }}>
                      {"Create Jira Configuration"}
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </div>

            {
              configurations?.map((configuration: any, idx: number) => (
                <div className='col-md-3 integration' key={idx}>
                  <Card stretch>
                    <CardHeader className='p-2 card-header integration-header'>
                      <CardLabel className="w-100 d-flex justify-content-center">
                        <CardTitle tag='h4' className='h5' >
                          {configuration["configuration_name"]}
                        </CardTitle>
                      </CardLabel>
                      {
                        <CardActions className='float-end'>
                          <Button
                            color='danger'
                            icon='Delete'
                            onClick={(event: any) => {
                              event.stopPropagation();
                              handleDeleteJiraConfiguration(configuration["id"])
                            }}>
                          </Button>
                        </CardActions>}
                    </CardHeader>
                    <CardBody className='row'>
                      {isFetching ?
                        <section className='d-flex justify-content-center align-items-center p-4'>
                          <Spinner />
                        </section> :
                        <>
                          <div className="col-3 d-flex justify-content-center">
                            <Icon className='fw-bold' icon="CustomJira" size={"5x"} />
                          </div>
                          <div className="col-9">

                            <div className="row">
                              <div className="col-12">
                                <span className='fw-bold'>{"Server URL:"}</span>
                                <span className="ms-1">{configuration["server_url"]}</span>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <span className='fw-bold'>{"Rest API Version:"}</span>
                                <span className="ms-1">{configuration["rest_api_version"]}</span>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <span className='fw-bold'>{"Created On:"}</span>
                                <span className="ms-1">{new Date(configuration["audit"]["created_on"]).toDateString()}</span>
                              </div>
                            </div>
                          </div>
                        </>
                      }
                    </CardBody>
                  </Card>
                </div>
              ))
            }
          </div>
          <div className='row'>
            <div className='col-12'>
              <div className='h4 fw-bold py-3'>Mappings</div>
            </div>
          </div>
          <div className="row">

            {
              repositories?.map((repository: any, idx: number) => (
                <div className='col-md-3 integration' key={idx}>
                  <Card stretch>
                    <CardHeader className='p-2 card-header integration-header'>
                      <CardLabel className="w-100 d-flex justify-content-center">
                        <CardTitle tag='h4' className='h5' >
                          {repository["name"]}
                        </CardTitle>
                      </CardLabel>
                    </CardHeader>
                    <CardBody>
                      <form>
                        <Formik
                          initialValues={{
                            configurationId: repository["issue_tracker_project"]?.configuration_id,
                            projectKey: repository["issue_tracker_project"]?.project_key,
                          }}
                          onSubmit={(values) => {
                            handleMappingUpdate({ "repositoryId": repository["id"], ...values })
                          }}>
                          {props => (<>
                            <div className='row'>
                              <div className='col-12'>
                                <FormGroupWrapper isError={false}>
                                  <FormGroup
                                    id="configurationId"
                                    isFloating
                                    label="Select Configuration"
                                    className={""}>
                                    <Select
                                      id="configurationId"
                                      name="configurationId"
                                      ariaLabel="Select Configuration"
                                      placeholder={"Select Configuration"}
                                      value={props.values.configurationId}
                                      isValid={props.isValid}
                                      onChange={props.handleChange}
                                      onBlur={props.handleBlur}
                                      onFocus={() => { props.setErrors({}); }}
                                      list={allConfigurationState}
                                    />
                                  </FormGroup>
                                </FormGroupWrapper>
                              </div>
                            </div>
                            <div className="row">
                              <div className='col-12'>
                                <FormGroupWrapper isError={false}>
                                  <FormGroup
                                    id="projectKey"
                                    isFloating
                                    label="Project Key"
                                    className={""}>
                                    <Input
                                      id="projectKey"
                                      name="projectKey"
                                      value={props.values.projectKey}
                                      isValid={props.isValid}
                                      onChange={props.handleChange}
                                      onBlur={props.handleBlur}
                                      defaultValue={""}
                                      onFocus={() => { props.setErrors({}); }}
                                    />
                                  </FormGroup>
                                </FormGroupWrapper>
                              </div>
                            </div>
                            <div className='row justify-content-center'>
                              <div className='col-12'>
                                <Button
                                  color='primary'
                                  size='lg'
                                  className='w-100'
                                  icon='Save'
                                  onClick={props.handleSubmit}>
                                  Update Mapping
                                </Button>
                              </div>
                            </div>
                          </>
                          )}

                        </Formik>
                      </form>
                    </CardBody>
                  </Card>
                </div>
              ))
            }
          </div>
        </section>

        <OffCanvas
          id='jiraConfigurationCanvas'
          titleId='jiraConfigurationCanvas'
          placement='end'
          isOpen={jiraConfigCanvasStatus}
          setOpen={setJiraConfigCanvasStatus}>
          <OffCanvasHeader setOpen={setJiraConfigCanvasStatus}>
            <OffCanvasTitle id='jiraConfigurationCanvas'>Jira Configuration</OffCanvasTitle>
          </OffCanvasHeader>
          <OffCanvasBody>
            <form className='row'>
              <FormGroupWrapper isError={!!jiraConfigurationFormik.errors.configurationName}>
                <FormGroup
                  id='configurationName'
                  isFloating
                  label="Configuration Name"
                  className={""}>
                  <Input
                    value={jiraConfigurationFormik.values.configurationName}
                    isTouched={jiraConfigurationFormik.touched.configurationName}
                    invalidFeedback={jiraConfigurationFormik.errors.configurationName}
                    isValid={jiraConfigurationFormik.isValid}
                    onChange={jiraConfigurationFormik.handleChange}
                    onBlur={jiraConfigurationFormik.handleBlur}
                    onFocus={() => { jiraConfigurationFormik.setErrors({}); }}
                  />
                </FormGroup>
              </FormGroupWrapper>

              <FormGroupWrapper isError={!!jiraConfigurationFormik.errors.serverUrl}>
                <FormGroup
                  id='serverUrl'
                  isFloating
                  label="Jira Server URL"
                  className={""}>
                  <Input
                    value={jiraConfigurationFormik.values.serverUrl}
                    isTouched={jiraConfigurationFormik.touched.serverUrl}
                    invalidFeedback={jiraConfigurationFormik.errors.serverUrl}
                    isValid={jiraConfigurationFormik.isValid}
                    onChange={jiraConfigurationFormik.handleChange}
                    onBlur={jiraConfigurationFormik.handleBlur}
                    onFocus={() => { jiraConfigurationFormik.setErrors({}); }}
                  />
                </FormGroup>
              </FormGroupWrapper>

              <FormGroupWrapper isError={!!jiraConfigurationFormik.errors.restApiVersion}>
                <FormGroup
                  id='restApiVersion'
                  isFloating
                  label="Rest API Version"
                  className={""}>
                  <Input
                    value={jiraConfigurationFormik.values.restApiVersion}
                    isTouched={jiraConfigurationFormik.touched.restApiVersion}
                    invalidFeedback={jiraConfigurationFormik.errors.restApiVersion}
                    isValid={jiraConfigurationFormik.isValid}
                    onChange={jiraConfigurationFormik.handleChange}
                    onBlur={jiraConfigurationFormik.handleBlur}
                    onFocus={() => { jiraConfigurationFormik.setErrors({}); }}
                  />
                </FormGroup>
              </FormGroupWrapper>

              <FormGroupWrapper isError={!!jiraConfigurationFormik.errors.accessToken}>
                <FormGroup
                  id='accessToken'
                  isFloating
                  label="Integration Access Token (IAT)"
                  className={""}>
                  <Input
                    type="password"
                    value={jiraConfigurationFormik.values.accessToken}
                    isTouched={jiraConfigurationFormik.touched.accessToken}
                    invalidFeedback={jiraConfigurationFormik.errors.accessToken}
                    isValid={jiraConfigurationFormik.isValid}
                    onChange={jiraConfigurationFormik.handleChange}
                    onBlur={jiraConfigurationFormik.handleBlur}
                    onFocus={() => { jiraConfigurationFormik.setErrors({}); }}
                  />
                </FormGroup>
              </FormGroupWrapper>

              <div className='col-12 m-0'>
                <Button
                  color='primary'
                  size='lg'
                  className='w-100'
                  icon='Add'
                  onClick={jiraConfigurationFormik.handleSubmit}>
                  {"Add Configuration"}
                </Button>
              </div>
            </form>
          </OffCanvasBody>
        </OffCanvas>

      </Page>
    </PageWrapper>);
}

export default JiraConfiguration;
