import { createAsyncThunk } from '@reduxjs/toolkit';
import HttpClient from '../../../libs/HttpClient';

const getInvitations = createAsyncThunk(
    'invitations/list',
    async ({ type }: {type: string}) => {
      return (
        await HttpClient.get(
            '/invitation', 
            {
                params: {
                    "type": type
                }
            }
        )
    ).data;
})

const updateInvitationStatus = createAsyncThunk(
    'invitations/update',
    async (
        { 
            invitationId,
            status, 
            type 
        }: {
            invitationId: string;
            status: string
            type: string
        }) => {
      return (
        await HttpClient.put(
            'invitation',
            {
                "type": type,
                "invitation_id": invitationId,
                "status": status
            }
        )
    ).data;
})
  
const InvitationRestService = {
    getInvitations,
    updateInvitationStatus
};

export default InvitationRestService;
