import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import Page from './Page/Page';

const AppLayout: FC = () => {
  return (
    <Page container="fluid" className='p-0'>
      <Outlet />
    </Page>
  );
};

export default AppLayout;
