import { useEffect, useState } from 'react';
import Badge from '../../../widgets/Badge';
import Button from '../../../widgets/Button';
import Spinner from '../../../widgets/Spinner';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../../widgets/Card';
import Icon from '../../../wrappers/Icon';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import IntegrationRestService from '../services/IntegrationRestService';
import { toast } from 'react-toastify';
import UserRestService from '../../user/services/UserRestService';
import { validateResponse } from '../../../utils/ResponseHelpers';

const GithubIntegration = () => {
  const GITHUB_APP_NAME = process.env.REACT_APP_GITHUB_APP_NAME;

  const { data: user, isFetching } = useAppSelector((store) => store.user.details);

  const dispatch = useAppDispatch();

  const [githubInstallationUrl, setGithubInstallationUrl] = useState<string>('');
  const [performedGitubIntegration, setPerformedGitubIntegration] = useState<boolean>(false);

  useEffect(() => {
    if (user === undefined || user.id === undefined || user.id === '') return;

    const state = btoa(JSON.stringify({ action: 'installation', entity: user.id }));
    setGithubInstallationUrl(
      `https://github.com/apps/${GITHUB_APP_NAME}/installations/new?state=${state}`,
    );

    setPerformedGitubIntegration(
      user['integrations'] !== undefined && user.integrations['GITHUB'] !== undefined,
    );
  }, [user, user.id, GITHUB_APP_NAME]);

  const handleSynchronizeWithGithub = () => {
    dispatch(IntegrationRestService.synchronizeWithGithub('GITHUB')).then((response) => {
      const payload = response.payload as any;

      if (payload === undefined) {
        toast.error('Error while synchronizing with Github');
        return;
      }

      const new_added = payload['new_added'].length;
      const regain_access = payload['regain_access'].length;
      const revoked_access = payload['revoked_access'].length;
      const total = payload['list'].length;

      toast.success(() => (
        <div>
          Synchronized with Github successfully
          <ul>
            <li>New Added: {new_added}</li>
            <li>Regain Access: {regain_access}</li>
            <li>Revoked Access: {revoked_access}</li>
            <li>Total: {total}</li>
          </ul>
        </div>
      ));
    });
  };

  const handleAddGithubIntegration = () => {
    const windowInstance = window.open(githubInstallationUrl, '_blank');
    if (!windowInstance) return;

    var timer = setInterval(function () {
      if (windowInstance.closed) {
        clearInterval(timer);
        dispatch(UserRestService.fetchUserDetails());
        handleSynchronizeWithGithub();
      }
    }, 1000);
  };

  const handleDeleteGithubIntegration = () => {
    dispatch(IntegrationRestService.deleteIntegration('GITHUB')).then((response) => {
      if (!validateResponse(response, 'Failed to delete github configuration')) return;

      toast.success('Github integration deleted successfully');
      dispatch(UserRestService.fetchUserDetails());
    });
  };

  return (
    <Card stretch>
      <CardHeader className='p-2 card-header integration-header'>
        <CardLabel className='w-100 d-flex justify-content-center'>
          <CardTitle tag='h4' className='h5'>
            {'Connect with GitHub'}
          </CardTitle>
        </CardLabel>
        {
          <Badge
            isLight
            color={performedGitubIntegration ? 'success' : 'danger'}
            className='px-3 py-2 w-25'>
            <Icon
              icon={performedGitubIntegration ? 'Link' : 'LinkOff'}
              size='lg'
              className='me-1'
            />
          </Badge>
        }
      </CardHeader>
      <CardBody className='row'>
        {isFetching ? (
          <section className='d-flex justify-content-center align-items-center p-4'>
            <Spinner />
          </section>
        ) : (
          <>
            <div className='col-3 d-flex justify-content-center'>
              <Icon className='fw-bold' icon='CustomGithub' size={'5x'} />
            </div>
            <div className='col-5'>
              <p>Connect your GitHub account with QConnect and manage permissions</p>
            </div>
            <div className='col-4'>
              {performedGitubIntegration && (
                <Button
                  color='primary'
                  icon='Sync'
                  className='mb-3 w-100'
                  onClick={(event: any) => {
                    event.stopPropagation();
                    handleSynchronizeWithGithub();
                  }}>
                  {'Manage'}
                </Button>
              )}
              <Button
                buttonType={!performedGitubIntegration ? 'primary-btn' : 'danger-btn'}
                icon={!performedGitubIntegration ? 'Link' : 'LinkOff'}
                className='mb-3 w-100 mt-2'
                onClick={(event: any) => {
                  event.stopPropagation();
                  !performedGitubIntegration
                    ? handleAddGithubIntegration()
                    : handleDeleteGithubIntegration();
                }}>
                {!performedGitubIntegration ? 'Connect' : 'Disconnect'}
              </Button>
            </div>
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default GithubIntegration;
