import { FC, useEffect, useState } from 'react';
import Card, {
  CardBody,
  CardHeader,
  CardLabel,
  CardTitle,
} from '../../../widgets/Card';
import Chart, { IChartOptions } from '../../../widgets/Chart';
import Theme from '../../../styles/theme';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import QualityRestService from '../services/QualityRestService';


const FileCodeChurnChart: FC = () => {

  const { data, isFetching } = useAppSelector((store) => store.quality.fileChurn)
  const { data: workspace, timeline } = useAppSelector((store) => store.workspace.selected);
  const repositoryIds = workspace.repositoryIds;
  const startDate = timeline.startDate;
  const endDate = timeline.endDate;
  
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      repositoryIds.length !== 0 &&
      startDate !== '' &&
      endDate !== ''
    ) {
      dispatch(QualityRestService.fetchFileChurn({
        startDate,
        endDate,
        repositoryIds,
      }));
    }
  }, [dispatch, repositoryIds, startDate, endDate]);

  const [chartState, setChartState] = useState<IChartOptions>({
    series: [
      {
        data: [],
      },
    ],
    options: {
      legend: {
        show: false,
      },
      chart: {
        height: 365,
        type: 'treemap',
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '12px',
        },
        // @ts-ignore
        formatter(text, op) {
          return [text, op.value];
        },
        offsetY: -4,
      },
      plotOptions: {
        treemap: {
          enableShades: true,
          shadeIntensity: 0.5,
          reverseNegativeShade: true,
          colorScale: {
            ranges: [
              {
                from: -1000,
                to: 0,
                color: '#CD363A',
              },
              {
                from: 0.001,
                to: 1000,
                color: '#52B12C',
              },
            ],
          },
        },
      },
    },
  });

  useEffect(() => {

    if (data === undefined || data.datasets === undefined || data.datasets.length === 0) return;

    setChartState((state) => ({
      series: data?.datasets.map((dataset: any, idx: number) => ({
        ...(state?.series?.[idx] as {} ?? {}),
        data: dataset,
      })) ?? [],
      options: {
        ...state.options,
        xaxis: {
          ...state.options.xaxis,
          categories: data?.labels ?? [],
        },
      }
    }));
  }, [data]);

  return (
    <div className='col-lg-12'>
      <Card stretch style={{
        "backgroundColor": Theme.colorPalette.primary[100],
      }}>
        <CardHeader style={{
          "backgroundColor": Theme.colorPalette.primary[100],
          "borderRadius": '2rem 2rem 0rem 0rem'
        }}>
          <CardLabel icon='AccountTree'>
            <CardTitle>
              {"File Code Churn"}
            </CardTitle>
          </CardLabel>
        </CardHeader>
        <CardBody>
          <Chart
            series={chartState.series}
            options={chartState.options}
            isLoading={isFetching}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default FileCodeChurnChart;
