import { FC, useEffect, useState } from 'react';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../../widgets/Card';

import Theme from '../../../styles/theme';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import SummaryRestService from '../services/SummaryRestService';
import Chart, { IChartOptions } from '../../../widgets/Chart';

const ActiveContributorChart: FC = () => {
  const { data, isFetching } = useAppSelector((store) => store.summary.mostActiveContributor);
  const { data: workspace, timeline } = useAppSelector((store) => store.workspace.selected);
  const repositoryIds = workspace.repositoryIds;
  const startDate = timeline.startDate;
  const endDate = timeline.endDate

  const [chartState, setChartState] = useState<IChartOptions>({
    series: [
      {
        name: 'Commits',
        data: [],
      },
    ],
    options: {
      chart: {
        height: 365,
        type: 'bar',
      },
      plotOptions: {
        bar: {
          borderRadius: 0,
          horizontal: true,
          barHeight: '80%',
          isFunnel: true,
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opt) {
          return opt.w.globals.labels[opt.dataPointIndex] + ':  ' + val;
        },
        dropShadow: {
          enabled: true,
        },
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        show: false,
      },
    },
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      repositoryIds.length !== 0 &&
      startDate !== '' &&
      endDate !== ''
    ) {
      dispatch(
        SummaryRestService.fetchMostActiveContributor({
          startDate,
          endDate,
          repositoryIds,
        }),
      );
    }
  }, [dispatch, repositoryIds, startDate, endDate]);

  useEffect(() => {
    if (data === undefined || data.datasets === undefined || data.datasets.length === 0) return;

    setChartState((state) => ({
      series:
        data?.datasets.map((dataset: any, idx: number) => ({
          ...((state?.series?.[idx] as {}) ?? {}),
          data: dataset,
        })) ?? [],
      options: {
        ...state.options,
        xaxis: {
          ...state.options.xaxis,
          categories: data?.labels ?? [],
        },
      },
    }));
  }, [data]);

  return (
    <div className='col-lg-12'>
      <Card
        stretch
        style={{
          backgroundColor: Theme.colorPalette.primary[100],
        }}>
        <CardHeader
          className=''
          style={{
            backgroundColor: Theme.colorPalette.primary[100],
            borderRadius: '2rem 2rem 0rem 0rem',
          }}>
          <CardLabel icon='TrendingUp'>
            <CardTitle>{'Active contributors'}</CardTitle>
          </CardLabel>
        </CardHeader>
        <CardBody>
          <Chart series={chartState.series} options={chartState.options} isLoading={isFetching} />
        </CardBody>
      </Card>
    </div>
  );
};

export default ActiveContributorChart;
