import CodeChurnByContributorsChart from "./charts/CodeChurnByContributorsChart";
import CodeSmellsDensityChart from "./charts/CodeSmellsDensityChart";
import CodeSmellsChart from "./charts/CodeSmellsChart";
import RefactoringChart from "./charts/RefactoringChart";
import FileCodeChurnChart from "./charts/FileCodeChurnChart";
import PageWrapper from "../../layouts/PageWrapper/PageWrapper";
import Page from "../../layouts/Page/Page";

const Quality = () => {

  return (
    <PageWrapper
      isProtected={false}
      title={'Quality'}
      className={'p-0'}>
      <Page container="fluid" className="px-5">

        <div className="row">
          <div className="col-md-6">
            <CodeChurnByContributorsChart />
          </div>
          <div className="col-md-6">
            <CodeSmellsDensityChart />
          </div>
        </div>

        <div className="row">
          <CodeSmellsChart />
        </div>

        <div className="row">
          <RefactoringChart />
        </div>

        <div className="row">
          <FileCodeChurnChart />
        </div>

      </Page>
    </PageWrapper>

  );
};

export default Quality;
