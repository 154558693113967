import { FC } from 'react';

import Footer from '../components/landing/Footer';
import Header from '../components/landing/Header';
import Page from '../layouts/Page/Page';
import PageWrapper from '../layouts/PageWrapper/PageWrapper';
import Analysis from '../assets/images/png/analysis.png';
import QconnectOverview from '../assets/images/png/QConnect.png';
import { Link } from 'react-router-dom';

const Overview: FC = () => {
  return (
    <PageWrapper isProtected={false} title={''} className='p-0'>
      <Page container='fluid' className='p-0 bg-primary'>
        <Header />
        <div className='container-fluid landing-page'>
          <div className='container-fluid w-75 justify-content-center align-items-center'>
            <div className='d-flex justify-content-center align-items-center w-80 vw-80'>
              <div className='col-md-3'>
                <img
                  src={Analysis}
                  alt='QConnect summary dashboard'
                  className='img-fluid width-image-10 rounded-lg m-5'
                />
              </div>

              <h1 className='display-3'>How QConnect can help your software development team</h1>
            </div>

            <div className='row justify-content-center mt-5 '>
              <p className='fs-4 lh-base lead'>
                QConnect empowers your software development team with data-driven insights by
                providing data-driven insights through seamless integration with developer tools and
                platforms to boost productivity and streamline workflows.
              </p>
              <h2 className='mt-5'>What QConnect offers?</h2>
              <ol className='fs-5 lh-base'>
                <li>
                  <b>Real-time productivity analytics: </b>
                  With QConnect, you get access to real-time analytics and reporting via its
                  productivity dashboard. Track frequency of code commits, PR review times, and code
                  churn to gain a clear understanding of your team's productivity and identify areas
                  for improvement.
                </li>
                <li>
                  <b>Seamless integration: </b>
                  QConnect seamlessly integrates with developer tools and platforms, such as GitHub
                  and Jira. The integration assures that QConnect collects diverse productivity
                  metrics automatically and mines data from multiple software development aritfacts,
                  tools, and platforms.
                </li>
                <li>
                  <b>Individual and team performance metrics: </b>
                  QConnect empowers development teams with a comprehensive suite of metrics and
                  indicators, offering a nuanced view of productivity and efficiency. With these
                  metrics, managers and team leads gain a holistic understanding of both overall
                  team performance and individual contributions.
                </li>
              </ol>

              <div className='col-md-9'>
                <figure className='figure'>
                  <img
                    src={QconnectOverview}
                    alt='QConnect summary dashboard'
                    className='figure-img img-fluid rounded-lg mt-5'
                  />
                  <figcaption className='figure-caption text-center'>
                    QConnect summary dashboard
                  </figcaption>
                </figure>
              </div>

              <h2 className='mt-5'>Use cases that make a difference</h2>
              <ol className='fs-5 lh-base'>
                <li>
                  <b>Project management: </b>
                  QConnect's integration with project management tools allows for better tracking of
                  project progress. Managers can easily monitor task completion rates, identify
                  overdue tasks, and allocate resources more effectively, ensuring projects stay on
                  schedule.
                </li>
                <li>
                  <b>Remote team management: </b>
                  In today's remote work environment, managing a distributed team can be
                  challenging. QConnect provides the tools needed to monitor productivity and
                  collaboration, making it easier to manage remote teams and ensure everyone is
                  working efficiently.
                </li>
                <li>
                  <b>Spot early warnings: </b>
                  The team managers would like to spot early warning signs related to the project’s
                  productivity. Insight derived from multiple information pieces could provide the
                  necessary nudge to the management to realize the need for support an individual or
                  the team requires.
                </li>
                <li>
                  <b>Knowledge decisions and reporting: </b>
                  QConnect empowers project managers and development teams to make informed
                  decisions based on historical data and project trends. With QConnect, teams can
                  identify patterns, anticipate challenges, and capitalize on opportunities,
                  transforming raw data into actionable insights that drive project success and team
                  performance.
                </li>
              </ol>
              <h2 className='mt-5'>Why your team can't afford to miss out</h2>
              <p className='fs-5 lh-base'>
                In an industry where efficiency is everything, QConnect gives you the edge you need
                to outperform the competition. It's not just about working harder—it's about working
                smarter. With QConnect, you're not just tracking productivity; you're unleashing
                your team's full potential.
              </p>
              <p className='fs-5 lh-base'>
                Ready to transform your development process?{' '}
                <Link to='/register'>
                  <b>Sign up today</b>
                </Link>{' '}
                for free and see the difference data-driven productivity can make. Your journey to
                peak performance starts here!
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </Page>
    </PageWrapper>
  );
};

export default Overview;
