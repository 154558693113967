import { FC, useEffect, useState } from 'react';
import Card, {
  CardBody,
  CardHeader,
  CardLabel,
  CardTitle,
} from '../../../widgets/Card';
import Chart, { IChartOptions } from '../../../widgets/Chart';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import Theme from '../../../styles/theme';
import SummaryRestService from '../services/SummaryRestService';

const IssueOpenClosedChart: FC = () => {

  const { data, isFetching } = useAppSelector((store) => store.summary.issuesOpenClosedData)
  const { data: workspace, timeline } = useAppSelector((store) => store.workspace.selected);

  const repositoryIds = workspace.repositoryIds;
  const startDate = timeline.startDate;
  const endDate = timeline.endDate;

  const [chartState, setChartState] = useState<IChartOptions>({
    series: [
      {
        name: 'No. of Open Issues',
        data: [],
      },
      {
      name: 'No. of Closed Issues',
        data: [],
      }
    ],
    options: {
      chart: {
        type: 'area',
        height: 365,
        zoom: {
          enabled: true,
        },
      },
      xaxis: {
        type: 'datetime',
        categories: [],
      }
    },
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      repositoryIds.length !== 0 &&
      startDate !== '' &&
      endDate !== ''
    ) {
      dispatch(SummaryRestService.fetchIssuesOpenClosedData({
        startDate,
        endDate,
        repositoryIds,
      }));
    }
  }, [dispatch, repositoryIds, startDate, endDate]);

  useEffect(() => {

    if (data === undefined || data.datasets === undefined || data.datasets.length === 0) return;

    setChartState((state) => ({
      series: data?.datasets.map((dataset: any, idx: number) => ({
        ...(state?.series?.[idx] as {} ?? {}),
        data: dataset,
      })) ?? [],
      options: {
        ...state.options,
        xaxis: {
          ...state.options.xaxis,
          categories: data?.labels ?? [],
        },
      }
    }));
  }, [data]);

  return (
    <div className='col-lg-12'>
      <Card stretch style={{
        "backgroundColor": Theme.colorPalette.primary[100],
      }}>
        <CardHeader style={{
          "backgroundColor": Theme.colorPalette.primary[100],
          "borderRadius": '2rem 2rem 0rem 0rem'
        }}>
          <CardLabel icon='MultilineChart'>
            <CardTitle>
              {"Issues"}
            </CardTitle>
          </CardLabel>
        </CardHeader>
        <CardBody>
          <Chart
            series={chartState.series}
            options={chartState.options}
            isLoading={isFetching} />
        </CardBody>
      </Card>
    </div>
  );
};

export default IssueOpenClosedChart;
