import React, { FC, HTMLAttributes, ReactElement } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';


interface IFormGroupWrapperProps extends HTMLAttributes<HTMLElement> {
	children: ReactElement | ReactElement[];
	isError: boolean;
	className?: string;
}
const FormGroupWrapper: FC<IFormGroupWrapperProps> = ({
	children,
	className,
	isError,
}) => {

	return (
		<div  style={{ "minHeight": "5.5rem" }}className={classNames(className)}>
			{children}
		</div>
	);
};
FormGroupWrapper.propTypes = {
	// @ts-ignore
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	isError: PropTypes.bool.isRequired,
	// @ts-ignore
	// eslint-disable-next-line react/require-default-props
};
FormGroupWrapper.defaultProps = {
	className: "col-12 mt-1",
	isError: false,
};

export default FormGroupWrapper;
