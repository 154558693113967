import * as Yup from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import Button from '../../../widgets/Button';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../../widgets/Card';
import WorkspaceRestService from '../services/WorkspaceRestService';
import FormGroupWrapper from '../../../widgets/forms/FormGroupWrapper';
import FormGroup from '../../../widgets/forms/FormGroup';
import Input from '../../../widgets/forms/Input';
import { validateResponse } from '../../../utils/ResponseHelpers';

const AddMemberSchema = Yup.object().shape({
  email: Yup.string().required('Member email address is required'),
});

const AddMember = () => {
  const { data: workspace } = useAppSelector((store) => store.workspace.selected);

  const dispatch = useAppDispatch();

  const memberFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
    },
    validationSchema: AddMemberSchema,
    validateOnChange: false,
    onSubmit: (values: { email: string }) => {
      dispatch(
        WorkspaceRestService.addWorkspaceMember({
          workspaceId: workspace.id,
          email: values.email,
        }),
      ).then((response) => {
        if (!validateResponse(response, 'Failed to add member')) return;

        toast.success('Member added successfully');
      });
    },
  });

  return (
    <Card stretch>
      <CardHeader className='p-2 card-header integration-header'>
        <CardLabel className='w-100 d-flex justify-content-center'>
          <CardTitle tag='h4' className='h5'>
            {'Add a new member'}
          </CardTitle>
        </CardLabel>
      </CardHeader>
      <CardBody className='d-flex align-items-center justify-content-center'>
        <form className='row w-100'>
          <div className='col-8'>
            <FormGroupWrapper isError={!!memberFormik.errors.email}>
              <FormGroup id='email' isFloating label='Member Email Address' className={''}>
                <Input
                  autoComplete='name'
                  value={memberFormik.values.email}
                  isTouched={memberFormik.touched.email}
                  invalidFeedback={memberFormik.errors.email}
                  isValid={memberFormik.isValid}
                  onChange={memberFormik.handleChange}
                  onBlur={memberFormik.handleBlur}
                  onFocus={() => {
                    memberFormik.setErrors({});
                  }}
                />
              </FormGroup>
            </FormGroupWrapper>
          </div>
          <div className='col-4 m-0'>
            <Button
              color='primary'
              size='lg'
              className='w-100'
              icon='PersonAdd'
              onClick={memberFormik.handleSubmit}>
              Add
            </Button>
          </div>
        </form>
      </CardBody>
    </Card>
  );
};

export default AddMember;
