import classNames from 'classnames';
import { FC } from 'react';
import { Link, Outlet } from 'react-router-dom';
import QConnectLogo from '../assets/logos/QConnectLogo';
import useDarkMode from '../hooks/useDarkMode';
import Page from '../layouts/Page/Page';
import PageWrapper from '../layouts/PageWrapper/PageWrapper';
import Card, { CardBody } from '../widgets/Card';

const AuthLayout: FC = () => {
  const { darkModeStatus } = useDarkMode();

  return (
    <PageWrapper isProtected={false} title={'Login'} className={'bg-secondary vh-100'}>
      <Page className='p-0'>
        <div className='row h-100 align-items-center justify-content-center'>
          <div className='col-lg-6 col-md-8 shadow-3d-container'>
            <Card className='shadow-3d-dark' data-tour='login-page'>
              <CardBody>
                <div className='text-center my-3'>
                  <Link
                    to='/'
                    className={classNames('text-decoration-none fw-bold display-2 text-light')}>
                    <QConnectLogo width={'30rem'} />
                  </Link>
                </div>
                <div
                  className={classNames('rounded-3', {
                    'bg-l10-dark': !darkModeStatus,
                    'bg-dark': darkModeStatus,
                  })}></div>

                <Outlet />
              </CardBody>
            </Card>

            <div className='text-center'>
              <a href='/' className={classNames('text-decoration-none me-3')}>
                Privacy policy
              </a>
              <a href='/' className={classNames('text-decoration-none')}>
                Terms of use
              </a>
            </div>
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
};

export default AuthLayout;
