import { createSlice } from '@reduxjs/toolkit'
import NotificationRestService from '../services/NotificationRestService'
import { NotificationModel } from '../types/NotificationModel';

const initialState : {
    list: {
        data: NotificationModel[];
        isFetching: boolean;
    }
} = {
  list: {
    data: [],
    isFetching: false,
  },
}

export const NotificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
        NotificationRestService.fetchNotifications.fulfilled, 
        (state, action) => {
            state.list.data = action.payload
            state.list.isFetching = false
    })

  }
})

// Action creators are generated for each case reducer function
export const NotificationActions = NotificationSlice.actions

export default NotificationSlice
