import Nav, { NavItem } from '../../widgets/Nav';
import { Link } from 'react-router-dom';

import { useAppSelector } from '../../hooks/useAppSelector';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import DatePicker from '../../widgets/DatePicker';
import { WorkspaceActions } from './slice/WorkspaceSlice';
import ManageProfile from '../header/ManageProfile';
import WorkspaceRestService from './services/WorkspaceRestService';
import { useEffect } from 'react';
import { validateResponse } from '../../utils/ResponseHelpers';
import QConnectLogo from '../../assets/logos/QConnectLogo';
import HomeIcon from '../../assets/images/png/home.png';
import SettingsIcon from '../../assets/images/png/settings.png';

const WorkspaceHeader = () => {
  const { timeline, data } = useAppSelector((store) => store.workspace.selected);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!data.id) return;

    dispatch(WorkspaceRestService.fetchWorkspaceTimeline(data.id)).then((response: any) => {
      if (!validateResponse(response, 'Failed to fetch default timeline', true)) return;

      const payload = response.payload.data;

      dispatch(
        WorkspaceActions.updateWorkspaceTimeline({
          startDate: payload['start_date'],
          endDate: payload['end_date'],
        }),
      );
    });
  }, [data.id, dispatch]);

  const handleDateChange = (startDate: any, endDate: any) => {
    dispatch(WorkspaceActions.updateWorkspaceTimeline({ startDate, endDate }));
  };

  return (
    <div className='p-3 bg-secondary sticky-top'>
      <div className='row'>
        <div className='col-sm-12'>
          <Nav
            tag='nav'
            design='pills'
            isFill={true}
            isJustified={true}
            isVertical={false}
            className='row align-items-center'>
            <div className='col-md-3 pe-5 col-sm-12 d-flex justify-content-start'>
              <NavItem className='w-75 me-auto'>
                <Link to='/' className='p-0'>
                  <QConnectLogo width={'12rem'} />
                </Link>
              </NavItem>
            </div>
            <div className='col-md-2 pe-5 col-sm-12 d-flex justify-content-start'></div>
            <div className='col-md-2 pe-5 col-sm-12 d-flex justify-content-start'>
              <NavItem>
                <Link to='/home' className='fs-5'>
                  <img src={HomeIcon} alt="User's home" title="User's home" width={'24rem'} />
                </Link>
              </NavItem>
              <NavItem>
                <span className='fs-5'>
                  {data.name}
                </span>
              </NavItem>
              <NavItem>
                <Link to='settings' className='fs-5'>
                  {/* Settings */}
                  <img
                    src={SettingsIcon}
                    alt='Settings'
                    title='Workspace settings'
                    width={'24rem'}
                  />
                </Link>
              </NavItem>
            </div>

            <div className='col-md-5 d-flex justify-content-end'>
              <DatePicker
                startDate={timeline.startDate}
                endDate={timeline.endDate}
                onChange={handleDateChange}
              />

              {/*	Profile */}
              <div className='col-auto ms-2'>
                <ManageProfile />
              </div>
            </div>
          </Nav>
        </div>
      </div>
    </div>
  );
};

export default WorkspaceHeader;
