import React, { FC } from 'react';
import FeatureCard, { IFeatureCardProps } from './FeatureCard';
import shape_3 from '../../../assets/images/svg/shape-3.svg'
import shape_3_1 from '../../../assets/images/svg/shape-3-1.svg';
import undraw_dark_analytics from '../../../assets/images/svg/undraw-dark-analytics-re-2kvy-1.svg';
import undraw_pie_graph from '../../../assets/images/svg/undraw-pie-graph-re-fvol-1.svg';
import undraw_report from '../../../assets/images/svg/undraw-report-re-f5n5-1.svg';
import undraw_visual_data from '../../../assets/images/svg/undraw-visual-data-re-mxxo-1.svg';
import classnames from 'classnames';

const Features: FC = () => {

  const cardDetails: Array<IFeatureCardProps> = [
    {
      background: {
        url: shape_3,
        position: "right",
      },
      heading: "What is QConnect?",
      subHeading: "",
      features: {
        design: {
          color: "black"
        },
        points: [
          "Present a productivity dashboard for software development projects",
          "Collects diverse productivity metrics automatically",
          "Mines data from multiple software development aritfacts, tools, and platforms",
          "Provides a comprehensive view of the development speed and efficiency",
          "Offers real-time insights to optimize your development process"
        ]
      },
      graphic: undraw_pie_graph,
    },
    {
      background: {
        url: shape_3_1,
        position: "left",
      },
      heading: "Track productivity of your team",
      subHeading: "",
      features: {
        design: {
          color: "black"
        },
        points: [
          "Track your team's productivity by visualizing a diverse set of metrics including commit activity, code churn, frequency of changes and more",
          "Monitor pull request efficiency of your team; track open, new, and closed PRs",
          "Stay on top of open and closed issues for better project management",
          "Spot top contributors, most active repositories and branches",
        ]
      },
      graphic: undraw_visual_data
    },
    {
      background: {
        url: shape_3,
        position: "right",
      },
      heading: "Transform raw data into powerful insights",
      subHeading: "",
      features: {
        design: {
          color: "black"
        },
        points: [
          "Unveil bottlenecks: Pinpoint issues consuming disproportionate team effort",
          "Streamline PR workflows: Detect and resolve pull request bottlenecks",
          "Decode team dynamics: Reveal unique contribution patterns and individual strengths within your team",
          "Empower your developers: Proactively identify team members who may benefit from additional support",
        ]
      },
      graphic: undraw_dark_analytics,
    },
    {
      background: {
        url: shape_3_1,
        position: "left",
      },
      heading: "Seemless integration with state-of-the-art tools",
      subHeading: "",
      features: { 
        design: {
          color: "black"
        },
        points: [
          "Harness repository metadata from GitHub for comprehensive code insights",
          "Extract issue tracking data from GitHub issues and Jira to align development with project goals",
          "Support for additional platforms such as GitLab coming soon",
        ]
      },
      graphic: undraw_report
    }]

  return (
    <>

      {cardDetails.map((card, index) => (
        <div key={index} className={classnames({
          'col-11': true,
          'mt-5': index !== 0,
          'p-0': true
        })}>
          <FeatureCard key={index} {...card} />
        </div>))}

    </>
  );
};

export default Features;
