import { FC } from "react";
import PageWrapper from "../layouts/PageWrapper/PageWrapper";
import Page from "../layouts/Page/Page";

const Success: FC = () => {

  setTimeout(() => {
    window.close();
  }, 1000);

  return (
    <PageWrapper
      isProtected={false}
      title={'Success'}
      className='p-0'>
      <Page container="fluid" className='p-5'>
        <div className="row">
          <div className="col-sm-12">
            <h1 className='text-center fw-bolder welcome-title'>
              {"Successfully connected with QConnect!"}
            </h1>
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
}

export default Success;
