import { FC, useEffect, useState } from 'react';
import Card, {
  CardBody,
  CardHeader,
  CardLabel,
  CardTitle,
} from '../../../widgets/Card';
import Chart, { IChartOptions } from '../../../widgets/Chart';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import QualityRestService from '../services/QualityRestService';
import Theme from '../../../styles/theme';

const CodeSmellsDensityChart: FC = () => {

  const { data, isFetching } = useAppSelector((store) => store.quality.smellTypeDensityData)
  const { data: workspace, timeline } = useAppSelector((store) => store.workspace.selected);

  const repositoryIds = workspace.repositoryIds;
  const startDate = timeline.startDate;
  const endDate = timeline.endDate;

  const [chartState, setChartState] = useState<IChartOptions>({
    series: [
      {
        name: "Architecture Smells",
        data: data?.datasets?.[0],
      },
      {
        name: "Implementation Smells",
        data: data?.datasets?.[1],
      },
      {
        name: "Desgin Smells",
        data: data?.datasets?.[2],
      },
      {
        name: "Testability Smells",
        data: data?.datasets?.[3],
      },
      {
        name: "Test Smells",
        data: data?.datasets?.[4],
      },

    ],
    options: {
      chart: {
        height: 365,
        type: 'line',
        zoom: {
          enabled: true,
        },
      },
      grid: {
        row: {
          colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: data?.labels ?? [],
      },
    },
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      repositoryIds.length !== 0 &&
      startDate !== '' &&
      endDate !== ''
    ) {
      dispatch(QualityRestService.fetchSmellTypeDensityOverTime({
        startDate,
        endDate,
        repositoryIds,
      }));
    }
  }, [dispatch, repositoryIds, startDate, endDate]);

  useEffect(() => {

    if (data === undefined || data.datasets === undefined || data.datasets.length === 0) return;

    setChartState((state) => ({
      series: data?.datasets.map((dataset: any, idx: number) => ({
        ...(state?.series?.[idx] as {} ?? {}),
        data: dataset,
      })) ?? [],
      options: {
        ...state.options,
        xaxis: {
          ...state.options.xaxis,
          categories: data?.labels ?? [],
        },
      }
    }));
  }, [data]);

  return (
    <div className='col-lg-12'>
      <Card stretch style={{
        "backgroundColor": Theme.colorPalette.primary[100],
      }}>
        <CardHeader style={{
          "backgroundColor": Theme.colorPalette.primary[100],
          "borderRadius": '2rem 2rem 0rem 0rem'
        }}>
          <CardLabel icon='ShowChart'>
            <CardTitle>
              {"Code Smell Density"}
            </CardTitle>
          </CardLabel>
        </CardHeader>
        <CardBody>
          <Chart
            series={chartState.series}
            options={chartState.options}
            isLoading={isFetching}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default CodeSmellsDensityChart;
